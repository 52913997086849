<template>
  <div>
    <VanImage
      :width="size"
      :height="size"
      :src="$img(src)"
      :round="round"
      v-if="round"
    >
      <VanImage
        slot="error"
        :width="size"
        :height="size"
        :src="require('@/assets/Img/myImg/MyOne.png')"
        :round="round"
      />
    </VanImage>
    <VanImage
      :width="size"
      :height="size"
      :src="$img(src)"
      :radius="radius"
      v-else
    >
      <VanImage
        slot="error"
        :width="size"
        :height="size"
        :src="require('@/assets/Img/myImg/MyOne.png')"
        :radius="radius"
      />
    </VanImage>
  </div>
</template>

<script>
import { Image as VanImage } from "vant";
export default {
  props: {
    size: {
      type: Number | String,
      default: 60,
    },
    src: "",
    round: {
      type: Boolean,
      default: true,
    },
    radius: {
      type: Number | String,
      String,
      default: 0,
    },
  },
  components: {
    VanImage,
  },
};
</script>

<style lang="less" scoped>
</style>