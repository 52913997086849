<template>
  <div class="content wrap">
    <div class="top_bar">
      <!-- <div class="title">
        <p>{{ $t("page.grab") }}</p>
      </div> -->
      <NavBar :title="$t('page.grab')">
        <template #right>
          <div class="record_bar" @click="$router.push('/recharge-list')">
            <img src="@/assets/ico/order.png" alt="">
          </div>
          <div class="record_bar" @click="$router.push('/cart')">
            <i class="iconfont icon-lishijilu"></i>
          </div>
        </template>
      </NavBar>
      <div class="userInfo_bar" :class="{ flex_reverse: lan === 'ar' }">
        <Avatar :src="userInfo.userimg" size="70" class="avatar" :style="lan === 'ar' && { marginLeft: 0, marginRight: 'calc(18rem / 16)' }
        " />
        <div class="right_bar" :class="{ text_end: lan === 'ar' }" :style="lan === 'ar' && { marginRight: 'calc(12rem / 16)' }
        ">
          <div class="nickname">{{ userInfo.username }}</div>
          <!-- <div class="id">
            <span class="label" v-if="lan !== 'ar'"> {{ "ID" | lan }}:&nbsp; </span>
            <span class="value">{{ userInfo.id || "" }}</span>
            <span class="label" v-if="lan === 'ar'">
              &nbsp;:{{ 'ID' | lan }}
            </span>
          </div>
          <div class="level_bar">
            <span class="value">{{ userInfo.rank_title }}</span>
          </div>
          <div class="balance_bar">
            <span class="label">{{ $t("Home.WithdrawSu.Balance") }}:&nbsp;</span>
            <span class="value">{{ $money(userInfo.money, false) }}</span>
          </div>
          <div class="balance_bar">
            <span class="label">{{ $t("mine.integral") }}:&nbsp;</span>
            <span class="value">{{ userInfo.point || 0 }}</span>
          </div> -->
          <div class="userInfo_content">
            <div class="userInfo_content_left">
              <div class="id">
                <span class="label" v-if="lan !== 'ar'"> {{ "ID" | lan }}:&nbsp; </span>
                <span class="value">{{ userInfo.id || "" }}</span>
                <span class="label" v-if="lan === 'ar'">
                  &nbsp;:{{ 'ID' | lan }}
                </span>
              </div>
              <div class="level_bar">
                <span class="value">{{ userInfo.rank_title }}</span>
              </div>
            </div>
            <div class="userInfo_content_right">
              <div class="balance_bar">
                <span class="label">{{ $t("Home.WithdrawSu.Balance") }}:&nbsp;</span>
                <span class="value">{{ $money(userInfo.money, false) }}</span>
              </div>
              <div class="balance_bar">
                <span class="label">{{ $t("mine.integral") }}:&nbsp;</span>
                <span class="value">{{ userInfo.point || 0 }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form_bar">
        <div class="submission_bar" :style="theme == 'light' ? { backgroundColor: 'var(--nav-background)' } : {}">
          <div class="title">{{ $t("newAdd.actualMoney") }}</div>
          <div class="input">
            <input type="number" v-model="newForm.money" />
          </div>
        </div>
        <div class="type_bar" v-if="!verConfig.banRechargeType">
          <div class="items" v-for="(item, index) in useNetWorkList" :key="index"
            :class="index === useNetWorkActive ? 'active' : ''"
            :style="(index == useNetWorkActive && theme == 'light') ? { color: 'var(--btnsizeColor)' } : {}"
            @click="handleTabItemClick(item, index)">
            <template v-if="item.lists.length > 0">
              {{ item.title }}
            </template>
          </div>
        </div>
        <div class="type_bar" v-if="!verConfig.banRechargeType">
          <div class="items"
            v-for="(item, index) in (useNetWorkList[useNetWorkActive] && useNetWorkList[useNetWorkActive].lists)"
            :key="index" :class="index === uselistNetWorkActive ? 'active' : ''"
            :style="(index == uselistNetWorkActive && theme == 'light') ? { color: 'var(--btnsizeColor)' } : {}"
            @click="listsTabItemClick(item, index)">
            {{ item.title }}
          </div>
        </div>
        <div class="submission_bar"
          :style="theme == 'light' ? { backgroundColor: 'var(--nav-background)', marginTop: 'calc(16rem / 16)' } : { marginTop: 'calc(16rem / 16)' }">
          <div class="title">{{ $t("page.Address") }}</div>
          <div class="input" style="border-bottom: none">
            <input type="text" disabled v-model="address" />
          </div>
          <div class="copy_icon" @click="copyClick">
            <img src="@/assets/ico/copy.png" alt="">
          </div>
        </div>
        <div class="textarea_wrap" :style="verConfig.banRechargeType && { marginTop: '15px' }">
          <textarea name="" id="" cols="30" rows="10" class="hash_bar" :placeholder="$t('hashPlaceholder')"
            v-model="newForm.hash"></textarea>
          <div class="btn_past" @click="handleCopy(newForm.hash)">
            {{ $t("newAdd.paste") }}
          </div>
        </div>
      </div>
      <div class="submit_bar" @click="handleCommit(true)">
        {{ $t("Login.Forgot.confirm") }}
      </div>
      <!-- <div class="task">
        <div class="task_title">{{ $t('grap.btask') }}</div>
        <div class="task_list">
          <div class="list" :class="{ list_content: lan == 'ar' }" v-for="item, index in task['bonus']" :key="index">
            <span class="list_label" v-if="lan !== 'ar'">{{ index + 1 }}:</span>
            <span class="list_value">

              <template v-if="item.url">
                <a :href="item.url">{{ item.title }}</a>
              </template>

              <template v-else>
                <a>{{ item.url }}</a>
              </template>
            </span>
            <span class="list_label list_left" v-if="lan == 'ar'">:{{ index + 1 }}</span>
          </div>
        </div>
      </div>
      <div class="task">
        <div class="task_title">{{ $t('grap.wtask') }}</div>
        <div class="task_list">
          <div class="list" :class="{ list_content: lan == 'ar' }" v-for="item, index in task['work']" :key="index">
            <span class="list_label" v-if="lan !== 'ar'">{{ index + 1 }}:</span>
            <span class="list_value">

              <template v-if="item.url">
                <a :href="item.url">{{ item.title }}</a>
              </template>

              <template v-else>
                <a>{{ item.url }}</a>
              </template>
            </span>
            <span class="list_label list_left" v-if="lan == 'ar'">:{{ index + 1 }}</span>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 轮播 -->
    <!-- <div class="marquee_wrap" :class="{ flex_reverse: lan === 'ar' }">
      <i class="iconfont icon icon-chakangonggao01"></i>
      <HallMarquee class="marquee" :height="34">

        <template v-slot="{ item }">
          <div class="halloffame">
            <div class="notice-item-wrap">
              <div>
                <div>{{ $t('bannerText1', { name: item.name, money: item.money, money1: item.money }) }}</div>
                <div>{{ $t('bannerText2', { name: item.name }) }}</div>
              </div>
            </div>
          </div>
        </template>
      </HallMarquee>
    </div> -->


    <!-- 下单按钮 -->
    <div class="game-btn" @click="handleBuy" v-if="ver != 'amigo_vi'"
      :style="theme == 'light' ? { color: 'var(--btnsizeColor)', backgroundColor: 'var(--btnColor)' } : { color: '#000' }">
      <div class="game-btn-text-bc">
        {{ $t("newAdd.OrderImmediately") }}
      </div>
    </div>
    <!-- 订单说明 -->
    <div class="make-synopsis">
      <div class="make-synopsis-title">
        <span>{{ $t("newAdd.OrderDescription") }}</span>
      </div>
      <div class="make-synopsis-content">
        <div>

          <template v-if="ver === 'myntra'">
            <p style="font-size: 16px">
              When you click auto-grab, the mall will automatically issue an
              order task. You need to complete the payment for the order. The
              order task needs to be completed within 60 minutes. After grabbing
              the order, please complete the task as soon as possible. <br />
              If you have any questions, please contact customer service.
            </p>
            <br />
          </template>

          <template v-else-if="ver === 'amigo_vi'">
            <p v-for="(item, index) in 7" :key="'p' + index">
              <strong><span style="color: var(--theme); font-size: 10px">({{ item }})</span></strong><span
                style="font-size: 10px">
                {{
        $t("viGrabDesces." + item, {
          rate: levels[userInfo.level]
            ? levels[userInfo.level].rate
            : 0,
        })
      }}
                <!-- {{ $t("newAdd.DescriptionRate") }} </span><span style="color: #e23d6d; font-size: 10px">{{
                levels[userInfo.level]
                ? levels[userInfo.level].rate
                : 0
                }}%</span><span style="font-size: 10px">
                {{ $t("newAdd.DescriptionPeriod") }} -->
              </span>
            </p>
            <p>
              <br />
            </p>
          </template>

          <template v-else>
            <!-- <p>
              <span style="color: #337fe5; font-size: 10px"><strong>(1)</strong></span><span style="font-size: 10px">
                {{ $t("newAdd.CouldMatch") }} </span><span style="color: #e23d6d; font-size: 10px">
                {{
                userInfo.level ?
                levels[userInfo.level] ? levels[userInfo.level].order_num :
                orderNumObj[userInfo.level]
                : userInfo.level == 0
                ? 1
                : 0
                }}
              </span><span style="font-size: 10px">
                {{ $t("newAdd.OrderPerDay") }}</span>
            </p> -->
            <p>
              <br />
            </p>
            <p>
              <strong><span style="color: var(--theme); font-size: 10px">(1)</span></strong><span
                style="font-size: 10px">
                {{ $t("newAdd.DescriptionRate") }} </span><span style="color: #e23d6d; font-size: 10px">{{
        levels[userInfo.level]
          ? levels[userInfo.level].rate
          : 0
      }}%</span><span style="font-size: 10px">
                {{ $t("newAdd.DescriptionPeriod") }}</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="color: var(--theme); font-size: 10px"><strong>(2)</strong></span><span
                style="font-size: 10px">
                {{ $t("newAdd.Description3") }}</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="color: var(--theme); font-size: 10px"><strong>(3)</strong></span><span
                style="font-size: 10px">
                {{ $t("newAdd.Description4") }}</span>
            </p>
            <p>
              <br />
            </p>
            <span style="color: #e53333; font-size: 10px">{{ $t("newAdd.Note") }} </span><span
              style="font-size: 10px">{{
        $t("newAdd.DescriptionNote")
      }}</span><br />
          </template>
        </div>
      </div>
      <div class="make-synopsis-bottom"></div>
    </div>

    <!-- 弹窗 -->
    <div class="buy-box" v-if="isBuyBoxShow">
      <div class="cont">
        <div>
          <div class="goodsInfo">
            <div class="close" @click="closeBuy">
              <img src="../../assets/icon/close.png" alt="" />
            </div>
            <p>{{ $t("Cart.zzInfo.buyIng") }}······</p>
          </div>
          <div>
            <van-progress :percentage="percentage" stroke-width="20" />
          </div>
          <span class="space-text">
            {{ $t("newAdd.WaitDispatch") }}
          </span>
        </div>
      </div>
      <div class="zz" @click="handleColesZZ"></div>
    </div>
    <!-- 商品展示 -->
    <van-overlay v-if="goodsShow" :show="goodsShow" z-index="10">
      <div class="wrapper">
        <div class="goods-block">
          <div class="goods-buy-info">
            <div class="goods-buy-item"></div>
            <p class="goods-buy-item-order-id colorBrown">
              {{ $t("OrderNum") }}{{ goodsInfo.order_no }}
            </p>
            <div class="goods-info-content">
              <div class="goods-info-image-box">
                <img class="goods-info-image" v-if="goodsInfo.product_picture"
                  :src="`${domain}${goodsInfo.product_picture}`" />
              </div>
              <div class="goods-info-item">
                <div class="goods-info-item-name">
                  <div class="goods-info-item-name-relative">
                    <p>{{ goodsInfo.product_name }}</p>
                  </div>
                </div>
                <div class="goods-info-item-info">
                  <div class="goods-info-item-info-monye">
                    {{ goodsInfo.price }}
                  </div>
                  <div class="goods-info-item-info-num">
                    X{{ goodsInfo.amount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="goods-buy-item">
              <span class="colorBrown">{{ $t("newAdd.TotalOrderPrice") }}</span>
              <p class="goods-info-money">{{ goodsInfo.total_price }}</p>
            </div>
            <div class="goods-buy-item">
              <span class="colorBrown">{{ $t("newAdd.commission") }}</span>
              <p class="goods-info-commission colorBrown">
                {{ (goodsInfo.total_price * goodsInfo.rate) / 100 }}
              </p>
            </div>
            <div class="goods-buy-item expected-item">
              <span>{{ $t("newAdd.ExpectedReturn") }}</span>
              <p class="goods-info-money">
                {{
        Number(goodsInfo.total_price) +
        (goodsInfo.total_price * goodsInfo.rate) / 100
      }}
              </p>
            </div>
          </div>
          <div class="pop_btn" @click="handleSubmitNow">
            {{ $t("newAdd.SubmitNow") }}
          </div>
          <div class="close-btn" @click="goodsShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <van-overlay v-if="identifyShow" :show="identifyShow" z-index="10">
      <div class="wrapper">
        <div class="goods-block">
          <div class="goods-buy-info">
            <div class="goods-buy-item"></div>
            <!-- <p class="goods-buy-item-order-id colorBrown">
              {{ $t("OrderNum") }}{{ identifyGoodInfo.id }}
            </p> -->
            <div class="goods-info-content">
              <div class="goods-info-image-box">
                <img class="goods-info-image" v-if="identifyGoodInfo.goods_image"
                  :src="`${identifyGoodInfo.goods_image}`" />
              </div>
              <div class="goods-info-item">
                <div class="goods-info-item-name">
                  <div class="goods-info-item-name-relative">
                    <p>{{ identifyGoodInfo.goods_name }}</p>
                  </div>
                </div>
                <div class="goods-info-item-info">
                  <div class="goods-info-item-info-monye">
                    {{ identifyGoodInfo.goods_price }}
                  </div>
                  <!-- <div class="goods-info-item-info-num">
                    X{{ goodsInfo.amount }}
                  </div> -->
                </div>
              </div>
            </div>
            <!-- <div class="goods-buy-item">
              <span class="colorBrown">{{ $t("newAdd.TotalOrderPrice") }}</span>
              <p class="goods-info-money">{{ goodsInfo.total_price }}</p>
            </div>
            <div class="goods-buy-item">
              <span class="colorBrown">{{ $t("newAdd.commission") }}</span>
              <p class="goods-info-commission colorBrown">
                {{ (goodsInfo.total_price * goodsInfo.rate) / 100 }}
              </p>
            </div>
            <div class="goods-buy-item expected-item">
              <span>{{ $t("newAdd.ExpectedReturn") }}</span>
              <p class="goods-info-money">
                {{
                Number(goodsInfo.total_price) +
                (goodsInfo.total_price * goodsInfo.rate) / 100
                }}
              </p>
            </div> -->
          </div>
          <div class="pop_btn" @click="toThirdRecharge">
            {{ $t("newAdd.SubmitNow") }}
          </div>
          <div class="close-btn" @click="goodsShow = false"></div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  Checkbox,
  CheckboxGroup,
  Card,
  SubmitBar,
  Toast,
  Icon,
  List,
  Cell,
  progress,
  Popup,
  NoticeBar,
  CountDown,
  Overlay,
  NavBar,
  Swipe,
  SwipeItem,
  Field,
} from "vant";
import Tabbar from "@/components/Tabbar";
import { domain, $get } from "@/utils/request";
import { REQUEST_API } from "@/http/api";
import { GET_TASK, GET_RECHARGE_LIST, RECHARGE_COMMIT } from "@/api";
import { countTime, getMyTime, deciTurnMoney } from "@/utils/tools";
import dataDetail from "@/utils/dataDetail";
import { formatDate } from "@/utils/tools";
import verConfig from "@/utils/verConfig";
import Br from "../../components/br.vue";
import getRandNoticeData from "@/utils/randNoticeData";
import HallMarquee from "@/components/hallMarquee.vue";
import Avatar from "@/components/avatar.vue";
import { mapGetters } from 'vuex';
import { cookie } from "../../utils/tools";
export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [progress.name]: progress,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [CountDown.name]: CountDown,
    [Overlay.name]: Overlay,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    Tabbar,
    Field,
    HallMarquee,
    Avatar,
    NavBar
  },

  data() {
    return {
      verConfig,
      domain: domain,
      country: cookie.get("language"),
      goodsInfo: {
        date: "2021-08-01 14:11:44",
        orderId: "202108010962489410",
        money: "500",
        commission: "700",
        expected: "6546",
        icon: "",
        name: "Chuột không dây sạc pin siêu mỏng 2.4GHz HXSJ M01Chuột không dây sạc pin siêu mỏng 2.4GHz HXSJ M01",
        num: "100",
      },
      goodsShow: false,
      gameGiftLists: [{ key: 0 }, { key: 1 }, { key: 2 }],
      makeRecordLists: [
        {
          label: this.$t("newAdd.AllocatCommissions"),
          value: "task_price",
        },
        {
          label: this.$t("newAdd.FrozenAmount"),
          value: "frozen_price",
        },
        // {
        //   label: this.$t("newAdd.TaskMatch"),
        //   value: "order_num",
        // },
        {
          label: this.$t("newAdd.AvailableBalance"),
          value: "money",
        },
      ],
      buyLoading: -1,
      listLoading: false,
      tableList: [
        {
          title: "V1",
        },
        {
          title: `2`,
        },
        {
          title: `3`,
        },
        {
          title: `4`,
        },
      ],
      statusBarList: [
        {
          label: `${this.$t("Cart.state.all")}`,
          key: null,
        },
        {
          label: `${this.$t("Cart.state.going")}`,
          key: "ing",
        },
        // {
        //   label: `${this.$t('Cart.state.paid')}`,
        //   key: 1,
        // },
        // {
        //   label: `${this.$t('Cart.state.frozen')}`,
        //   key: 5,
        // },
        {
          label: `${this.$t("Cart.state.over")}`,
          key: 2,
        },
      ],
      tableKey: 0,
      statusKey: null,
      moneyInfoList: [
        {
          label: `${this.$t("Cart.info.allMoney")}`,
          key: "allMoney",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.dayAllOrder")}`,
          key: "dayAllOrder",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.dayTrustMoney")}`,
          key: "dayTrustMoney",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.yesterdayMoney")}`,
          key: "yesterdayMoney",
          value: 0,
        },
      ],
      list: [],
      loading: false,
      finished: false,
      isBuyBoxShow: false,
      isBuyFailShow: false,
      isBuyFailNotMoneyShow: false,
      percentage: 0,
      level: 1,
      pageNo: 1,
      buyGoodsForm: {}, // 购买当前商品信息，可用可不用
      popupShow: false,
      interval: "",
      pageNow: 1,
      newList: [],
      // userInfo: {},
      orderError: "",
      btnTime: 0,
      btnInterval: null,
      getOrderSuccess: true,
      rate: "",
      noticeArr: [],
      noticeShowIndex: 3,
      // levelData: [],
      orderNumObj: {
        0: 1,
        1: "1~3",
        2: "1~6",
        3: "1~7",
        4: "1~8",
        5: "1~9",
      },
      urlInput: "",
      identifyGoodInfo: {},
      identifyShow: false,
      rechargePath: this.verConfig.pathNewRecharge
        ? "third-recharge-n"
        : "new-recharge",
      task: {},
      newForm: {
        money: 10,
        netWork: "",
        hash: "",
      },
      useNetWorkList: [
        {
          "type": "btc",
          "title": "BTC",
          "lists": []
        },
        {
          "type": "usdc",
          "title": "USDC",
          "lists": []
        },
        {
          "type": "usdt",
          "title": "USDT",
          "lists": [
            {
              "id": 28,
              "title": "TRC-20",
              "desc": "TRC-20",
              "step": "TRC-20",
              "type": "qrcode-USDT",
              "inorder": 0,
              "letter": 0,
              "min": 0,
              "max": 0,
              "show_min": 0,
              "show_max": 0
            }
          ]
        }
      ],
      useNetWorkActive: 0,
      address: '',
      uselistNetWorkActive: 0
    };
  },

  computed: {
    ...mapGetters(["userInfo", "levels", "theme"]),
    // submitBarText() {
    //   const count = this.checkedGoods.length;
    //   return '结算' + (count ? `(${count})` : '');
    // },
    // userInfo(){
    //   return this.$store.state.user.userInfo
    // },
    totalPrice() {
      return this.goods.reduce(
        (total, item) =>
          total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0),
        0
      );
    },
  },
  created() {
    this.init();
    this.getTasks()
    this.useNetWorkList = this.useNetWorkList.filter((item, index) => {
      if (!item.lists || item.lists.length) {
        return item
      }
    })
    // this.getMoneyInfo();
    // this.getLevels()
    // this.getTaskOrder()
    // this.getUserInfo();
    // this.getLevel();
    this.getPayList();
    // this.handleCommit();
  },
  mounted() {
    if (this.$route.params.level) {
      this.tableKey = this.$route.params.level;
    } else {
      this.tableKey = 0;
    }
    this.initTitlePosition();
    // this.noticeIni();
    this.noticeArr = getRandNoticeData();
    // this.noticeDataUpdate();
  },
  methods: {
    async copyClick() {
      if (!this.address) return Toast('Copy Fail')
      this.$copyText(this.address).then((e) => {
        Toast(this.$t('copySuccess'))
      })
    },
    handleCommit(falg = false) {
      const { hash, money } = this.newForm;
      let params = {
        id: this.useNetWorkList[this.useNetWorkActive].lists[this.uselistNetWorkActive].id,
        money: money,
        account: this.userInfo.userphone
      }
      if (falg) {
        if (!hash) return Toast(this.$t("newAdd.commitHint"));
        params = { ...params, hash, isbefore: 1 }
      }
      this.paySubmit(params);
    },
    async paySubmit(params) {
      Toast.loading();
      try {
        const res = await RECHARGE_COMMIT(params);
        const { ret } = res.data;
        if (ret == 1) {
          Toast.clear();
          this.address = res.data.data.setting.url;
          if (params.hash) {
            return this.$router.push('/recharge-list')
          }
          return
        }
        Toast(res.data.msg)
      } catch (error) {
        console.error(error);
      }
    },
    async handleCopy(copyText) {
      let pasteStr = await navigator.clipboard.readText();
      this.newForm.hash = pasteStr;
    },
    handleTabItemClick(item, index) {
      if (this.useNetWorkActive === index) return;
      this.useNetWorkActive = index;
      this.newForm.netWork = item.title;
      this.handleCommit();
    },
    listsTabItemClick(item, index) {
      if (this.listsTabItemClick == index) return;
      this.uselistNetWorkActive = index;
      this.handleCommit();
    },
    async getPayList() {
      try {
        const res = await GET_RECHARGE_LIST();
        const { ret, data } = res.data;
        if (ret == 1) {
          this.$cookie.set('useNetWorkList', JSON.stringify(data))
          const newData = JSON.parse(this.$cookie.get('useNetWorkList'))
          this.useNetWorkList = newData.filter((item, index) => {
            if (!item.lists || item.lists.length) {
              return item
            }
          })

          // this.useNetWorkList = data;
          setTimeout(() => {
            this.handleCommit();
          }, 1000)
        } else {
          const newData = JSON.parse(this.$cookie.get('useNetWorkList') || '{}')
          this.useNetWorkList = newData && newData.filter((item, index) => {
            if (!item.lists || item.lists.length) {
              return item
            }
          })
          setTimeout(() => {
            this.handleCommit();
          }, 1000)
        }
        // console.error(res.data);
      } catch (error) {
        const newData = JSON.parse(this.$cookie.get('useNetWorkList') || '{}')
        this.useNetWorkList = newData && newData.filter((item, index) => {
          if (!item.lists || item.lists.length) {
            return item
          }
        })
        setTimeout(() => {
          this.handleCommit();
        }, 1000)
        // console.error(error);
      }
    },
    async getTasks() {
      try {
        const res = await GET_TASK();
        this.task = res.data
      } catch (error) {
        console.error('getTasks', error);
      }
    },
    randName() {
      let name =
        `+${this.verConfig.areaCode || ""}\n` +
        String(dataDetail.rand(90, 99)) +
        dataDetail.rand(3, 9) +
        "****" +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10);
      return name;
    },
    randMoney() {
      const interval = this.verConfig.homeProfitInterval || [100, 300000];
      let probability = dataDetail.rand(0, 100);
      let res = 0;
      if (probability <= 30) {
        // if(this.verConfig.homeProfitFormat){
        //   return this.moneyFormat(dataDetail.rand(interval[0], interval[1] / 20))
        // }
        return dataDetail.rand(interval[0], interval[1] / 20);
      } else {
        // if(this.verConfig.homeProfitFormat){
        //   return this.moneyFormat(dataDetail.rand(interval[1] / 20, interval[1]))
        // }
        return dataDetail.rand(interval[1] / 20, interval[1]);
      }
    },
    moneyFormat(money) {
      const arr = money.toString().split("").reverse();
      for (let i = 0, len = arr.length; i < Math.floor(len / 3); i++) {
        arr.splice(3 * (i + 1) + i, 0, ".");
      }
      if (arr[arr.length - 1] === ".") {
        arr.pop();
      }
      return arr.reverse().join("");
    },
    noticeIni() {
      for (let i = 0; i < 5; i++) {
        this.noticeArr.push({
          name: this.randName(),
          money: this.randMoney(),
        });
      }
    },
    noticeDataUpdate() {
      setInterval(() => {
        this.noticeArr.splice(this.noticeShowIndex, 1, {
          name: this.randName(),
          money: this.randMoney(),
        });
        if (this.noticeShowIndex >= 4) {
          this.noticeShowIndex = 1;
        } else {
          this.noticeShowIndex++;
        }
      }, 3000);
    },
    dateEvent(indate) {
      console.log(indate);
      return formatDate("", indate);
    },
    progress60() {
      let auth_timetimer = setInterval(() => {
        this.percentage++;
        if (this.percentage > 30) {
          clearInterval(auth_timetimer);
        }
      }, 20);
      let auth_timetimer2 = setInterval(() => {
        if (this.percentage >= 31 && this.percentage <= 70) {
          this.percentage++;
        }
        if (this.percentage > 60) {
          clearInterval(auth_timetimer2);
        }
      }, 80);
    },
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo(
        (res) => {
          // this.userInfo = res;
          this.makeRecordLists[2].value = this.userInfo.order_num;
          this.makeRecordLists[0].value = this.userInfo.task_price;
          this.makeRecordLists[1].value = this.userInfo.frozen_price;
          this.makeRecordLists[3].value = this.userInfo.money;
          // this.makeRecordLists[2].value =
          //   this.userInfo.task_status === 2
          //     ? 0
          //     : this.userInfo.order_num - this.userInfo.complete_order;
        },
        (err) => {
          Toast.clear();
        },
        (r) => {
          this.$api.getLevel((res) => {
            this.makeRecordLists[0].value =
              (this.userInfo.task_price * res[this.userInfo.level].rate) / 100;
            this.rate = res[this.userInfo.level].rate;
            Toast.clear();
          });
        }
      );
    },
    getLevel() {
      this.$api.getLevel((res) => {
        this.levelData = res;
        Toast.clear();
      });
    },
    /** handle **/
    handleWithOrder() { },
    handleSubmitNow() {
      this.$router.push({ name: "cart" });
    },
    handleFindExplain() {
      this.popupShow = true;
    },
    handleTable(index) {
      this.listLoading = true;
      if (index === this.tabelKey) {
        return false;
      }
      this.tableKey = index;
      this.getTaskOrder();
      // let list = [
      //   'SliverMember',
      //   'GoldMember',
      //   'PlatinumMember',
      //   'DiamondMember',
      // ]
      // list.forEach((_, i) => {
      //   if (this.tabelKey === _) {
      //     this.level = i + 1
      //     this.list = []
      //     this.finished = false
      //     this.pageNo = 1
      //   }
      // })
    },
    statusSearch(item) {
      this.newList = [];
      this.listLoading = true;
      if (item.key === this.statusKey) {
        return false;
      }
      this.statusKey = item.key;
      this.getTaskOrder();
    },
    getCommissionRate(level) {
      if (!level) return "0%";
      const { rate } = level;
      const rateArr = rate.split(",");
      if (rateArr.length === 1) return rate + "%";
      return `${Math.min.apply(null, rateArr)}~${Math.max.apply(
        null,
        rateArr
      )}%`;
    },
    handleBuy(item) {
      if (this.$cookie.get("token")) {
        // if (
        //   this.userInfo.order_num - this.userInfo.complete_order &&
        //   this.userInfo.task_status !== 2
        // ) {
        this.isBuyBoxShow = true;
        this.percentage = 0;
        this.$api.getTask(
          { level: this.userInfo.level },
          (res) => {
            this.getOrderSuccess = true;
            let auth_timetimer3 = setInterval(() => {
              if (this.percentage >= 61 && this.percentage < 100) {
                this.percentage++;
              }
              if (this.percentage === 100) {
                clearInterval(auth_timetimer3);
                this.goodsInfo = res;
                this.updateUserInfo();
                setTimeout(() => {
                  this.isBuyBoxShow = false;
                  // 打开订单提示框
                  this.goodsShow = true;
                }, 300);
              }
            }, 30);
          },
          (err) => {
            this.getOrderSuccess = false;
            this.interval = setInterval(() => {
              this.$api.getTask(
                { level: this.userInfo.level },
                (r) => {
                  this.getOrderSuccess = true;
                  let auth_timetimer3 = setInterval(() => {
                    if (this.percentage >= 61 && this.percentage < 100) {
                      this.percentage++;
                    }
                    if (this.percentage === 100) {
                      clearInterval(auth_timetimer3);
                      this.goodsInfo = r;
                      this.updateUserInfo();
                      this.orderError = "";
                      setTimeout(() => {
                        this.isBuyBoxShow = false;
                        // 打开订单提示框
                        this.goodsShow = true;
                      }, 300);
                    }
                  }, 30);
                  clearInterval(this.interval);
                },
                (e) => {
                  this.getOrderSuccess = false;
                  this.orderError = e;
                }
              );
            }, 3000);
            let auth_timetimer3 = setInterval(() => {
              if (this.percentage >= 61 && this.percentage < 99) {
                this.percentage++;
              }
              if (this.percentage === 99) {
                clearInterval(auth_timetimer3);
                /**
                    setTimeout(() => {
                      clearInterval(this.interval);
                      setTimeout(()=>{
                        if(!this.getOrderSuccess){
                          if(this.orderError == ''){
                            this.$toast(this.$t('newAdd.NotBeenSentOrder'));
                          }else{
                            this.$toast(this.orderError)
                          }
                        }
                        this.orderError = '';
                        // 按钮允许点击
                        this.isBuyBoxShow = false;
                      },1000)
                    }, 29000) */
                // 新修改去除长时间等待
                clearInterval(this.interval);
                setTimeout(() => {
                  if (!this.getOrderSuccess) {
                    if (this.orderError == "") {
                      this.$toast(this.$t("newAdd.NotBeenSentOrder"));
                    } else {
                      this.$toast(this.orderError);
                    }
                  }
                  this.orderError = "";
                  // 按钮允许点击
                  this.isBuyBoxShow = false;
                }, 500);
              }
            }, 100);
          }
        );
        this.progress60();
        // } else {
        //   this.$toast(this.$t("newAdd.TodayOrderOver"));
        // }
      } else {
        this.$toast(this.$t("newAdd.NotLogin"));
        setTimeout(() => {
          this.$router.replace({ name: "login" });
        }, 1000);
      }
    },
    async Identify() {
      if (!this.urlInput) return;
      Toast.loading();
      try {
        const res = await $get("/third/parse/url", { url: this.urlInput });
        Toast.clear();
        const { ret, data, msg } = res.data;
        if (ret === 1) {
          this.identifyGoodInfo = data;
          this.identifyShow = true;
          return;
        }
        Toast(msg);
      } catch (error) {
        Toast.clear();
      }
    },
    toThirdRecharge() {
      if (this.ver == "amigo_vi") {
        this.$router.push("/cart");
        return;
      }
      let pathUrl = "/ThirdRecharge";
      let type = "third";
      if (this.verConfig.pathNewRecharge) {
        pathUrl = "/third-recharge-n";
      }
      this.$router.push(
        `${pathUrl}?type=${type}&data=${JSON.stringify(this.identifyGoodInfo)}`
      );
      localStorage.setItem(
        "rechargeGoodDataInfo",
        JSON.stringify({ data: this.identifyGoodInfo, type: "third" })
      );
    },
    handleBuyPercentage(item) {
      if (this.percentage < 99 && this.btnTime !== 0) {
        return false;
      } else {
        this.$api.toService();
      }
      // this.sendBuy(this.buyGoodsForm.id)
    },
    handleInviteFriends() {
      this.$router.push("InviteFriends"); // 邀请好友
    },
    handleBuyVip() {
      this.isBuyFailShow = false;
      // console.log('立即购买VIP')
      this.$router.push("Recharge");
    },
    updateUserInfo() {
      if (this.$cookie.get("userInfo")) {
        let userInfo = JSON.parse(this.$cookie.get("userInfo"));
        delete userInfo.level;
        this.$cookie.set("userInfo", JSON.stringify(userInfo));
        this.$api.getUserInfo();
      }
    },
    handlecolsePopBox() {
      this.isBuyBoxShow = false;
      this.isBuyFailShow = false;
      this.isBuyFailNotMoneyShow = false;
      this.buyGoodsForm = {};
    },
    handleColesZZ() {
      this.isBuyBoxShow = false;
      this.isBuyFailShow = false;
      this.isBuyFailNotMoneyShow = false;
      this.buyGoodsForm = {};
    },
    /** api **/
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    getGoodsList() {
      if (this.finished) {
        return false;
      }
      let form = {
        // page: this.pageNo,
        // pageNum: 10,
        uid: this.userInfo[1].data.player.uid,
        level: this.level,
        cmd: "201002",
      };
      REQUEST_API(form)
        .then((r) => {
          // console.log(r);
          if (!r.data.sc) {
            // console.log(r.data.notifyList[0].data)
            if (r.data.notifyList[0].data.length) {
              this.list.push(...r.data.notifyList[0].data);
              // this.finished = r.data.notifyList[0].data.page === r.data.notifyList[0].data.pageSize
              this.finished = true;
              this.pageNo++;
              if (this.finished) {
                this.loading = false;
              }
            } else {
              this.list = [];
              this.loading = false;
              this.finished = true;
            }
          } else {
            Toast(this.$t("Hint.failed"));
            this.list = [];
            this.loading = false;
            this.finished = true;
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    getMoneyInfo() {
      let form = {
        uid: this.userInfo[1].data.player.uid,
        cmd: "201004",
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            let moneyForm = r.data.notifyList[0].data;
            // console.log(moneyForm);
            this.moneyInfoList[0].value = deciTurnMoney(moneyForm.diamond) || 0;
            this.moneyInfoList[1].value = moneyForm.size || 0;
            this.moneyInfoList[2].value = deciTurnMoney(moneyForm.price) || 0;
            this.moneyInfoList[3].value =
              deciTurnMoney(moneyForm.last_reward) || 0;
            // console.log(this.moneyInfoList);
          } else {
            Toast(this.$t("Hint.failed"));
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    sendBuy(id) {
      let form = {
        id,
        cmd: "201003",
        uid: this.userInfo[1].data.player.uid,
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            Toast(this.$t("Hint.success"));
            this.handleColesZZ();
            this.list = [];
            this.finished = false;
            this.pageNo = 1;
            this.getMoneyInfo();
          } else if (r.data.sc === 1002) {
            this.isBuyBoxShow = false;
            // this.isBuyFailShow = true
            this.isBuyFailNotMoneyShow = true;
            Toast(this.$t("Hint.failed"));
          } else if (r.data.sc === 1211) {
            Toast(this.$t("Hint.goodsNumNo"));
          } else {
            Toast(this.$t("Hint.failed"));
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    /** 其他 **/
    init() {
      // this.userInfo = this.getUserInfoNew();
      this.makeRecordLists[3] &&
        (this.makeRecordLists[3].value = this.userInfo.money);
      this.level = this.$route.query.id ? Number(this.$route.query.id) + 1 : 1;
      this.tableList.forEach((_, i) => {
        if (i + 1 === this.level) {
          this.tabelKey = _.key;
        }
      });
    },
    getUserInfoNew() {
      let val = this.$cookie.get("userInfo");
      if (val) {
        let value = JSON.parse(this.$cookie.get("userInfo"));
        if (Object.keys(value).length) {
          return value;
        } else {
          return { money_not: "", money: "0" };
        }
      } else {
        return { money_not: "", money: "0" };
      }
    },
    initTitlePosition() {
      if (this.tableKey > 2) {
        let title_wrap = document.querySelector(".classify");
        title_wrap.scrollLeft = document.documentElement.clientWidth;
      }
    },
    /** 抢单 */
    rushOrder() {
      // this.isBuyBoxShow = true;
      // setTimeout(()=>{
      //   this.isBuyBoxShow = false;
      // },5000)
      // this.$api.getTask(res=>{
      //   console.log(res);
      //   this.isBuyBoxShow = false;
      // },err=>{
      // });
    },
    getLevels() {
      if (this.$cookie.get("levels")) {
        this.tableList = JSON.parse(this.$cookie.get("levels"));
      }
      this.tableKey = 0;
    },
    closeBuy() {
      this.isBuyBoxShow = false;
      this.getOrderSuccess = true;
      clearInterval(this.interval);
    },
    getTaskOrder() {
      if (this.finished) {
        return false;
      }
      let data = {
        level: this.tableKey,
        page: this.pageNow,
        status: this.statusKey,
      };
      this.$api.getTaskOrder(
        data,
        (res) => {
          this.newList = res.lists;
          this.listLoading = false;
        },
        (err) => {
          this.newList = [];
          this.listLoading = false;
        }
      );
    },
    toPay(row, index) {
      this.buyLoading = index;
      // this.newList[index].buyLoading = true;
      this.$api.payTaskOrder(
        row.id,
        (res) => {
          this.getTaskOrder();
          this.buyLoading = -1;
        },
        (err) => {
          console.log(err);
          if (err.ret == 0) {
            this.$toast(err.msg);
            setTimeout(() => {
              if (this.verConfig.pathNewRecharge)
                this.$router.push({ name: "thirdRechargeNew" });
              else this.$router.push({ name: "NewRecharge" });
            }, 1000);
          } else {
            if (err.msg) {
              this.$toast(err.msg);
            }
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@comMargin: calc(12rem / 16);
@comRadius: calc(10rem / 16);
@bg: var(--bg);
@sizeColor: var(--sizeColor);
@Bc: var(--nav-background);
@sizeColor: var(--sizeColor);

.van-hairline--bottom::after {
  border-bottom: 1px solid transparent;
}

.record_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--theme);
  width: calc(40rem / 16);
  height: calc(40rem / 16);
  border-radius: 50%;
  margin-right: calc(10rem / 16);

  .iconfont {
    color: #fff;
    font-size: calc(36rem / 16);
  }

  img {
    height: calc(28rem / 16);
  }
}

.record_bar:nth-last-child(1) {
  margin-right: 0;
}

// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

@background: var(--nav-background);

.flex_reverse {
  flex-direction: row-reverse;

  div {
    text-align: right;
  }

  .icon {
    transform: rotate(180deg);
    margin-right: 0;
    margin-left: calc(14rem / 16);
  }
}

.wrap {
  min-height: 100vh;
  // background: var(--nav-background);
}

.top_bar {
  // background: @background;
  // background: #fff;
  overflow: hidden;

  // .title {
  //   color: var(--theme);
  //   height: calc(49rem / 16);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   // background: @background;
  //   font-weight: 400;
  //   font-size: calc(18rem / 16);
  //   position: relative;
  //   width: 100%;
  //   // margin-bottom: calc(12rem / 16);
  //   background-color: @background;
  // }
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.spancolor {
  color: @Color;
}

.wrapper {
  .flex();
  height: 100vh;

  .goods-info-image {
    width: 20vw;
    // float: left;
  }

  .goods-buy-item,
  .goods-info-item-info {
    line-height: 1.5em;
    .flex(space-between);
  }

  .goods-info-item {
    font-size: 14px;

    .goods-info-item-info {
      padding: 4vw 0;
    }
  }

  .goods-block {
    .colorBrown {
      color: #a8a8a8;
    }

    width: 70vw;
    // height: 80vh;
    background-color: #ceb198;
    border-radius: 5vw;
    padding: 5vw 2vw 6vw 2vw;

    .goods-buy-info {
      .goods-buy-item-order-id {
        padding: 4vw 0;
        font-style: 13px;
      }

      .goods-info-content {
        display: flex;
      }

      font-size: 15px;
      padding: 3vw;
      border-radius: 2vw;
      background-color: #fff;

      .goods-info-money {
        color: #ff9307;
      }

      .goods-info-image {
        width: calc(80rem / 16);
        height: calc(88rem / 16);
      }
    }
  }

  .pop_btn {
    background-color: #ff3c00;
    width: 80%;
    margin: 0 auto;
    margin-top: 5vw;
    text-align: center;
    padding: 3vw 0;
    border-radius: 2vw;
    color: #fff;
  }
}

.content {
  background: @background;

  .content_title {
    img {
      width: 100%;
    }
  }

  .game-btn {
    width: 90%;
    text-align: center;
    // background: @bg;
    // background-image: linear-gradient(to bottom, var(--blueColor),var(--sizeColor));
    background: @Color;
    margin: 0 auto;
    // color: #fff;
    // color: @Color;
    color: var(--sizeColor);
    padding: 2.667vw 0;
    border-radius: 999px;
    margin-top: 20px;
  }

  .textarea_wrap {
    width: 90%;
    margin: 20px auto;

    .textarea {
      border-radius: 6px;
    }

    /deep/ textarea {
      color: #4f5860;
    }

    .submit_btn {
      text-align: center;
      background: linear-gradient(to right, #0dccff, #3c7bca);
      margin: 0 auto;
      color: #fff;
      padding: 2.667vw 0;
      border-radius: 999px;
      margin-top: 20px;
    }
  }

  .make-record {
    background-color: #fff;
    width: 85%;
    border-radius: 1vw;

    margin: 0 auto;
    padding: 4.8vw 1.333vw 2.667vw 4vw;
    box-shadow: 0 0.533vw 1.333vw 0 rgba(255, 255, 255, 0.1);
    margin-top: 20px;
    font-size: 12px;

    .make-record-lists {
      text-align: center;
      .flex(center, wrap);

      .make-record-item {
        .make-record-item-icon {
          width: 20px;
          height: 20px;
        }

        padding: 10px;
      }
    }
  }

  // 下单说明
  .make-synopsis {
    width: 100vw;
    // background-color: #fff;
    background-color: @bg;
    margin-top: 20px;
    font-size: 3.733vw;

    .make-synopsis-title {
      text-align: center;
      color: @Color;
      padding: 10px 0;
    }

    .make-synopsis-content {
      padding: 0 10vw 30px 10vw;
      color: @sizeColor;
    }
  }

  .marquee_wrap {
    display: flex;
    background-color: @bg;
    padding: 4.8vw 1.333vw 2.667vw 4vw;
    box-shadow: 0 0.533vw 1.333vw 0 rgba(255, 255, 255, 0.1);
    align-items: center;

    .icon {
      font-size: calc(24rem / 16);
      margin-right: calc(14rem / 16);
      color: var(--theme);
    }
  }

  // 轮播
  .marquee {
    // width: 85%;
    // margin: 0 auto !important;
    // margin-top: 20px;
    // height: calc(34rem / 16);
    // margin-left: 5vw;
    border-radius: 1vw;

    .halloffame {
      // padding: 0 9vw;
      font-size: 12px;
      line-height: 3em;
      color: var(--theme);
    }
  }

  .buy-box {
    width: 100%;
    height: 100%;
    // position: relative;
    position: fixed;
    // top: -100%;
    top: 0;

    >.cont {
      width: calc(350rem / 16);
      height: calc(460rem / 16);
      // background: url('../../assets/Img/goods/img/DDSC_0001_xdz.png') no-repeat;
      position: absolute;
      z-index: 44;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      >div {
        width: calc(350rem / 18);
        height: calc(460rem / 18);
        margin: 0 auto;
        background: url("../../assets/Img/goods/img/DDSC_0001_xdz.png") no-repeat;
        background-size: 100% 100%;

        >.goodsInfo {
          padding-top: calc(250rem / 16);
          display: flex;
          align-items: center;
          // justify-content: space-between;
          margin-bottom: calc(5rem / 16);
          position: relative;

          >.close {
            position: absolute;
            right: calc(10rem / 16);
            top: calc(120rem / 16);
          }

          >div {
            display: flex;
            align-items: center;
            font-size: calc(13rem / 16);
            color: #9b8383;

            >img {
              width: calc(30rem / 16);
              height: calc(30rem / 16);
              border-radius: 6px;
            }

            >div {
              display: flex;
              flex-direction: column;
              margin-left: calc(15rem / 16);
              width: calc(70rem / 16);
            }
          }

          >p {
            font-size: calc(18rem / 16);
            text-align: center;
            color: #9b8383;
            // margin-bottom: calc(2rem / 16);
            margin: 0 0 calc(2rem / 16) calc(15rem / 16);
          }
        }

        >div {
          width: 98%;
          margin-left: calc(6rem / 16);
          // margin: auto;
        }

        >span {
          font-size: calc(18rem / 16);
          display: block;
          text-align: center;
          color: #9b8383;
          // margin: calc(15rem / 16) calc(22rem / 16) calc(2rem / 16) 0;
          margin: calc(15rem / 16) 0 calc(2rem / 16) 0;
        }

        >button {
          width: calc(100% - (60rem / 16));
          height: calc(60rem / 16);
          margin: auto;
          display: block;
          // margin-right: calc(40rem / 16);
          border-radius: 30px;
          border: 0;
          color: #fff;
          background: #c4cec9;
          font-size: calc(22rem / 16);
          font-weight: 200;

          &.on {
            background: #48db8d;
          }
        }
      }
    }
  }
}

.userInfo_bar {
  display: flex;
  align-items: center;
  margin: @comMargin;
  // background: #fff;
  background: @bg;
  border-radius: @comRadius;
  padding: calc(16rem / 16) 0;
  // margin-top: 0;

  .avatar {
    margin-left: calc(18rem / 16);
  }

  .right_bar {
    margin: 0 calc(12rem / 16);
    flex: 1;

    >div {
      margin-top: calc(6rem / 16);

      &:nth-child(1) {
        margin-top: 0;
        color: @Color;
      }

      .label {
        // color: #666;

        color: @Color;
        font-size: calc(15rem / 16);
      }

      .value {
        // color: #000;
        color: @Color;
      }
    }
  }

  .userInfo_content {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
}

.task {
  // background-color: #fff;
  background-color: @bg;
  margin: @comMargin;
  padding: @comMargin;
  border-radius: @comRadius;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 calc(40rem / 16);

  &_title {
    font-size: calc(16rem / 16);
    // color: #000;
    color: @Color;
    margin: calc(8rem / 16) 0;
  }

  &_list {
    width: 100%;
    line-height: calc(24rem / 16);

    .list {
      flex: 1;
      overflow: hidden;
      margin: calc(4rem / 16) 0;
      text-align: left;
      .text-overflow();

      &_label {
        // color: #333;
        color: var(--theme);
        margin-right: calc(8rem / 16);
        margin-left: 0;
      }

      &_left {
        margin-left: calc(8rem / 16);
        margin-right: 0;
      }

      &_value {
        a {
          color: @Color;
        }
      }
    }

    .list_content {
      text-align: right;
    }
  }
}

.form_bar {
  margin: 0 calc(13rem / 16);
  padding: calc(13rem / 16);
  border-radius: calc(10rem / 16);
  // background: #0271dc;
  background: @bg;

  .submission_bar {
    @height: calc(60rem / 16);
    height: @height;
    // background: #fff;
    background: var(--nav-background);
    border-radius: calc(5rem / 16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 calc(10rem / 16);

    .title {
      font-size: calc(12rem / 16);
      // color: #6e87a0;
      // font-family: PingFangSC-Medium;
      white-space: nowrap;
      color: @Color;
    }

    >.input {
      margin-left: calc(10rem / 16);
      flex-grow: 1;
      overflow: hidden;
      // border-bottom: 1px solid #0271dc;
      border-bottom: 1px solid @Color;
      display: flex;
      // align-items: center;
      align-items: flex-end;

      >input {
        border: none;
        height: calc(@height * 0.5);
        // color: #0271dc;
        color: @Color;
        background: transparent;
      }
    }

    .copy_icon {
      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .type_bar {
    padding: calc(8rem / 16) calc(10rem / 16);
    display: flex;
    justify-content: space-between;
    background-color: var(--nav-background);
    margin-top: calc(16rem / 16);
    border-radius: calc(16rem / 16);


    >div {
      line-height: calc(35rem / 16);
      text-align: center;
      // color: #fff;
      color: @sizeColor;
      // font-family: PingFangSC-Medium;
      font-size: calc(12rem / 16);
      font-weight: normal;
      white-space: nowrap;
      padding: 0 calc(12rem / 16);
      border-radius: calc(10rem / 16);
      flex: 1;
    }

    .active {
      // background: #fff;
      background: var(--btnColor);
      color: var(--newbtnsizeColor);
      // color: #0271dc;
    }
  }

  .textarea_wrap {
    position: relative;
    padding: calc(13rem / 16);
    // background: #fff;
    background: @bg;
    border-radius: calc(5rem / 16);
    border: 2px solid @Bc;

    .hash_bar {
      // background: #fff;
      height: calc(125rem / 16);
      border: none;
      width: 100%;
      background-color: @bg;
      color: @Color;
    }

    .btn_past {
      border-radius: 50%;
      @size: calc(50rem / 16);
      width: @size;
      line-height: @size;
      text-align: center;
      // background-color: #5a00ff;
      background-color: var(--btnColor);
      // color: #fff;
      color: var(--newbtnsizeColor);
      // color: @Bc;
      position: absolute;
      bottom: calc(13rem / 16);
      right: calc(13rem / 16);

      &:active {
        opacity: 0.8;
      }
    }
  }
}

.submit_bar {
  width: 90%;
  text-align: center;
  line-height: calc(40rem / 16);
  color: var(--theme);
  border-radius: calc(999rem / 16);
  border: 1px solid var(--theme);
  margin: 0 auto;
  margin-top: calc(16rem / 16);
}
</style>
